import { Fragment, useEffect, useRef, useState } from "react";
import BreadCrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";

import PageHeading from "../../../components/page-heading/PageHeading";
import { RouteConstants } from "../../../routes/RouteConstants";

import {
  Button,
  Modal,
  styled,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./qrGeneration.module.css";

import { useDispatch, useSelector } from "react-redux";
import Retry from "components/retry/Retry";
import Loader from "components/loader/Loader";
import { ButtonComponent, TextFieldComponent } from "components/ui-elements";
import { Box } from "@mui/system";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import CancelIcon from "@mui/icons-material/Cancel";
import QrListActions from "../../../redux-container/qr-generation/qrRedux";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import convertUTCToLocalDateTime from "../../../utils/FormatUtcToLocal";
const CustomTableContainer = styled(Box)`
  &&.MuiTableContainer-root {
    overflow-x: none;
  }
  && .MuiOutlinedInput-root {
    margin-right: 55px;
  }
`;
export const BreadCrumbsWrapper = styled(Box)`
  && .MuiOutlinedInput-root {
    margin-right: 50px;
  }
  .MuiButton-root {
    /* margin-right: 6px; */
  }
`;
export const ReadmoreDialogWrapper = styled(Box)`
  && .MuiPaper-root {
    overflow-wrap: break-word;
    width: 200px;
    margin-right: 20px;
  }
`;
const actionDispatch = (dispatch: any) => {
  return {
    getQrList: (params: any) => dispatch(QrListActions.getQrRequest(params)),
    generateQr: (data: any) => dispatch(QrListActions.generateQrRequest(data)),
    updateQr: (id: any, data: any) =>
      dispatch(QrListActions.updateQrRequest(id, data)),
    deleteQr: (id: any) => dispatch(QrListActions.deleteQrRequest(id)),
    setQrState: (key: any, data: any) => {
      dispatch(QrListActions.setQrState(key, data));
    },
  };
};
const QrGeneration = () => {
  const { getQrList, generateQr, updateQr, deleteQr, setQrState } =
    actionDispatch(useDispatch());

  const breadcrumbRoutes: any = [
    {
      name: "Resource Management",
      route: RouteConstants.resourceManagementDashboard,
    },
    {
      name: "QR Generation",
      route: RouteConstants.insightQrGeneration,
    },
  ];
  

  const [open, setOpen] = useState(false);
  const [openQr, setOpenQr] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [url, setUrl] = useState("");
  const [useCase, setUseCase] = useState("");
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDisable, setisDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState({ url: "", useCase: "" });
  const [isUrlWrong, setIsUrlWrong] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { generatedQrData, rowsPerPage, totalCount, isError, message } =
    useSelector((state: any) => state.qrGenerationList);
  const isLoading = useSelector(
    (state: any) => state.qrGenerationList.isLoading
  );
  const page = useSelector((state: any) => state.qrGenerationList.page);

  const handleView = (row: any) => {
    setSelectedRow(row);
    setOpenQr(true);
  };
  const copyToClipboard = (url: string) => {
    navigator.clipboard.writeText(url);
    setOpen(true);
  };
  const downloadQRCode = (qrCodeUrl: string) => {
    if (!qrCodeUrl) return;
    const link = document.createElement("a");
    link.href = qrCodeUrl;
    link.download = "qrcode.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setQrState("page", newPage +1);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQrState("rowsPerPage", Number(event.target.value));
    setQrState("page", 1);
  };

  const renderRetry = () => {
    return (
      <Retry
        message={message}
        retryHandler={() => {
          setQrState("page", 1);
          setQrState("rowsPerPage", 10);
          getQrList({ page: 1, limit: 10 });
        }}
      />
    );
  };

  function validateString(input: string): boolean {
    const onlyAlphabetsAndNumbersRegex = /^[A-Za-z0-9\s]+$/;
    const containsSpecialCharRegex = /[!@#$%^&*(),.?":{}|<>-]/;
    const consecutiveSpaceRegex = /\s{2,}/;
    if (consecutiveSpaceRegex.test(input)) {
        return false;
    }

    if (onlyAlphabetsAndNumbersRegex.test(input)) {
        return true; 
    }
    if (containsSpecialCharRegex.test(input) && /[A-Za-z0-9]/.test(input)) {
        return true;
    } 
    return false;
}

  function validateUrl(url: string) {
    url = url;
    const regex = /^(http:\/\/|https:\/\/)/;
    const spaceRegex = /\s/;
    if (!url) return true;
    else if (!regex.test(url)) {
      setErrorMessage({
        ...errorMessage,
        url: "Invalid URL: URL should start with http:// or https://",
      });
      return false;
    } else if (url.includes(" ")) {
      setErrorMessage({ ...errorMessage, url: "Spaces are not allowed" });
      return false;
    } else if (spaceRegex.test(url)) {
      setErrorMessage({
        ...errorMessage,
        url: "spaces are not allowed in the URL",
      });
      return false;
    }
    try {
      const parsedUrl = new URL(url);
    } catch (e) {
      return false; // Invalid URL
    }
    setErrorMessage({
      ...errorMessage,
      url: "",
    });
    return true;
  }

  function checkErrorMessage(): boolean {
 return !!errorMessage.url || !!errorMessage.useCase;
  }

  const handleTextInput = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "useCase") setUseCase(value);
    else {
      setUrl(value);
    }

    if (name === "url" && validateUrl(value)) {
      setErrorMessage({ ...errorMessage, url: "" });
      setIsUrlWrong(false);
      !!useCase && !checkErrorMessage() && setisDisabled(false); //checks for filled use case to disable/enable button
    } else if (name === "url") {
      setIsUrlWrong(true);
      setErrorMessage({ ...errorMessage, url: "Please enter correct url" });
    }
    if (name === "useCase" && validateString(value)) {
      setErrorMessage({ ...errorMessage, useCase: "" });
      !!url && !checkErrorMessage() && setisDisabled(false); //checks for filled url to disable/enable button
    } else if (name === "useCase")
      setErrorMessage({
        ...errorMessage,
        useCase: "Please enter a valid use case",
      });
      
      


  };

  const renderLoader = () => {
    return <Loader />;
  };
  useEffect(() => {
    getQrList({ page, limit: rowsPerPage});
  }, [rowsPerPage,page]);

  useEffect(() => {
    if (isError && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, [isError]);
  useEffect(() => {
    if (useCase && url && !errorMessage.useCase && !errorMessage.url) {
      setisDisabled(false);
    } else {
      setisDisabled(true);
    }
  }, [useCase, url, errorMessage]);
  const renderTable = () => {
    return (
      <Fragment>
        <div className="heading-action-holder">
          <h4 className="heading">QR Generation</h4>
        </div>

        <Grid container spacing={1}>
          <Grid container spacing={2} md={8} lg={10} xl={10}
            sx={{ minWidth: isEdit ? "100% !important" : "auto" }}
          >
            <Grid item md={5} lg={5} xl={5} sx={{ maxWidth: "33% !important" }}>
              <FormControl>
                <TextFieldComponent
                  value={url}
                  type="text"
                  label="Enter URL"
                  onChange={(e)=>handleTextInput(e)}
                  onBlur={() => !validateUrl(url) && setIsUrlWrong(() => true)}
                  name="url"
                  error={isUrlWrong}
                ></TextFieldComponent>
              </FormControl>
              {!!errorMessage.url && (
                <div
                  id={`url-helper-text`}
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginTop: "4px",
                  }}
                >
                  {`${errorMessage?.url}`}
                </div>
              )}
            </Grid>
            <Grid item md={3} lg={3} xl={3}>
              <FormControl>
                <TextFieldComponent
                  value={useCase}
                  disabled={isEdit}
                  type="text"
                  label="Use Case"
                  onChange={(e)=>handleTextInput(e)}
                  onBlur={() =>
                    !validateString(useCase) &&
                    setErrorMessage((prev) => {
                      return {
                        ...prev,
                        useCase: "Please enter valid use case",
                      };
                    })
                  }
                  error={!!errorMessage.useCase}
                  name="useCase"
                ></TextFieldComponent>
              </FormControl>
              {!!errorMessage.useCase && (
                <div
                  id={`url-helper-text`}
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginTop: "4px",
                  }}
                >
                  {`${errorMessage?.useCase}`}
                </div>
              )}
            </Grid>
            <Grid 
              item
              md={3.5}
              lg={3.5}
              xl={3.5}
              className={styles.url_btn_grp}
            >
              <div className={styles.url_btn}>
                <Button
                  variant="contained"
                  onClick={async () => {
                    try {
                      if (isEdit) {
                       await updateQr(selectedRow.id, { originalUrl: url });
                      } else {
                        await generateQr({ originalUrl: url, useCase });
                      }
                      if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                      }
                
                      timeoutRef.current = setTimeout(() => {
                        getQrList({ page, limit: rowsPerPage });
                      }, 1000);

                      setUrl("");
                      setUseCase("");
                      setIsEdit(false);
                    setisDisabled(true);

                    } catch (error) {
                      console.info("API failed:", error);
                    }
                  }}
                  // autoFocus
                  sx={{
                    color: isDisable ? "grey !important" : "",
                    border: isDisable ? "1px solid transparent !important" : "",
                  }}
                  disableElevation
                  className="btn btn-dark"
                  disabled={isDisable}
                >
                  {isEdit ? "Update" : "Generate"}
                </Button>
              </div>
              {isEdit && (
                <div className={styles.url_btn}>
                  <ButtonComponent
                    onClick={() => {
                      setIsEdit(false);
                      setUrl("");
                      setUseCase("");
                    }}
                  >
                    Cancel
                  </ButtonComponent>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <CustomTableContainer>
          <TableContainer
            className={`table-container`}
            style={{ height: window.innerHeight - 475 }}
            component={Paper}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" className="table-header-text">
                    SR.
                  </TableCell>
                  <TableCell align="center" className="table-header-text">
                    Use Case
                  </TableCell>
                  <TableCell align="center" className="table-header-text">
                    Generated URL
                  </TableCell>
                  <TableCell align="center" className="table-header-text">
                    Generated Time
                  </TableCell>
                  <TableCell align="center" className="table-header-text">
                    Number of Redirections
                  </TableCell>
                  <TableCell align="center" className="table-header-text">
                    QR Codes
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {generatedQrData?.map((row: any, index: any) => (
                  <TableRow className="table-data-row" key={row.id}>
                    <TableCell align="center" className="table-data-text">
                      {index + 1 + ((page - 1) * rowsPerPage )}
                    </TableCell>
                    <TableCell align="center" className="table-data-text">
                      {row.useCase}
                    </TableCell>
                    <TableCell align="center" className="table-data-text">
                      <a
                        href={`https://cms-api-uat.hoabl.in/hoabl-admin/redirect/${row.shortUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {row.shortUrl}
                      </a>
                      <IconButton
                        onClick={() =>
                          copyToClipboard(
                            `https://cms-api-uat.hoabl.in/hoabl-admin/redirect/${row.shortUrl}`
                          )
                        }
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </TableCell>

                    <TableCell align="center" className="table-data-text">
                      {convertUTCToLocalDateTime(row.createdAt)}
                    </TableCell>
                    <TableCell align="center" className="table-data-text">
                      {row.redirectCount}
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: 150 }}>
                      <Tooltip title="View" placement="top">
                        <IconButton
                          aria-label="view"
                          onClick={() => handleView(row)}
                        >
                          <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="edit" placement="top">
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            setIsEdit(true);
                            setSelectedRow(row);
                            setUrl(row.originalUrl);
                            setUseCase(row.useCase);
                          }}
                        >
                          <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="delete" placement="top">
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            setIsDeleteConfirm(true);
                            setSelectedRow(row);
                          }}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="download" placement="top">
                        <IconButton aria-label="download">
                          <DownloadForOfflineIcon
                            onClick={() => downloadQRCode(row.qrCode)}
                            sx={{ color: "green" }}
                            className="table-action-icon"
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {totalCount > 0 ? (
              <TablePagination
                rowsPerPageOptions={[10, 50, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                // page={Math.min(page, Math.ceil(totalCount / rowsPerPage) - 1)}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
            <Snackbar
              open={open}
              autoHideDuration={2000}
              onClose={() => setOpen(false)}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Alert onClose={() => setOpen(false)} severity="success">
                URL copied
              </Alert>
            </Snackbar>
            <Modal
              open={openQr}
              onClose={() => {
                setOpenQr(false);
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: 2,
                }}
              >
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    color: "gray",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpenQr(false)}
                >
                  <CancelIcon />
                </IconButton>
                <Typography variant="h6">QR Code of the Ad Preview</Typography>
                {selectedRow && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <img
                        style={{ width: "100%" }}
                        src={selectedRow.qrCode}
                        alt="qr-code"
                      />
                    </div>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Typography>
                        Generated Time{" "}
                        {convertUTCToLocalDateTime(selectedRow.createdAt)}
                      </Typography>
                      <Typography
                        onClick={() => downloadQRCode(selectedRow.qrCode)}
                        sx={{
                          backgroundColor: "green",
                          color: "white",
                          px: 1,
                          py: 0.5,
                          borderRadius: 1,
                          cursor: "pointer",
                        }}
                      >
                        Download
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Modal>
          </TableContainer>
        </CustomTableContainer>
      </Fragment>
    );
  };

  const render = () => {
    if (isError) {
      return renderRetry();
    }
    else if (isLoading === false) {
      return renderTable();
    } else if (isLoading) {
      return renderLoader();
    } 
  };

  return (
    <Fragment>
      <BreadCrumbsWrapper>
        <PageHeading title="Resource Management" />
        <BreadCrumbs routes={breadcrumbRoutes} placeholder={""}></BreadCrumbs>
        <ConfirmationDialog
          shouldOpen={isDeleteConfirm}
          title="Delete this Qr"
          content="This Action will delete the QR, Are you sure you want to continue?"
          okText="Yes"
          cancelHandler={() => {
            setIsDeleteConfirm(false);
          }}
          okHandler={() => {
            deleteQr(selectedRow?.id);
            setIsDeleteConfirm(false);
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
            }
      
            timeoutRef.current = setTimeout(() => {
              getQrList({ page, limit: rowsPerPage });
            }, 1000);
          }}
        />
        <Container className="main-container">{render()}</Container>
      </BreadCrumbsWrapper>
    </Fragment>
  );
};

export default QrGeneration;
