import { createActions, createReducer } from "reduxsauce";

export const INITIAL_STATE = {
  isLoading: false,
  generatedQrData: [],
  totalCount: 0,
  page: 1,
  rowsPerPage: 10,
  message: "",
  isError:false,
};

const { Types, Creators } = createActions({
  getQrRequest: ["data"],
  getQrSuccess: ["response"],
  getQrFailure: ["error"],

  generateQrRequest: ["data"],
  generateQrSuccess: ["response"],
  generateQrFailure: ["error"],

  updateQrRequest: ["id", "data"],
  updateQrSuccess: ["response"],
  updateQrFailure: ["error"],

  deleteQrRequest: ["id"],
  deleteQrSuccess: ["response"],
  deleteQrFailure: ["error"],
  setQrState: ["key", "value"],
  
});

export const QrTypes = Types;
export default Creators;

const getQrRequest = (state:any) => ({
  ...state,
  isLoading: true,
  isError: false,
});

const getQrSuccess = (state: any, action: any) => {

  return {
    ...state,
    generatedQrData: action?.response.urls || [],
    totalCount: action?.response?.total || 0,
    page: action?.response?.page||1,
    rowsPerPage: action?.response?.limit||10,
    isLoading: false,
    isError: false,
  };
};

const getQrFailure = (state:any, action:any) => {
  return {
    ...state,
    isLoading: false,
    message: action?.error?.message || "Error fetching QR codes",
    isError: true,
}};

const generateQrRequest = (state:any) => {
  return{
    ...state,
    isLoading: true,
    isError: false,
  }
};

const generateQrSuccess = (state:any, action:any) => {
  return {
    ...state,
    isLoading: false,
    isError: false,
  }
};

const generateQrFailure = (state:any, action:any) => {
  return{
    ...state,
    isLoading: false,
    message: action?.error?.message || "Error generating QR code",
    isError: true,
  }
};

const updateQrRequest = (state:any) => ({
  ...state,
  isLoading: true,
  isError: false,
});

const updateQrSuccess = (state:any, action:any) => {
  
  return { ...state, isLoading: false, isError: false  };
};

const updateQrFailure = (state:any, action:any) => {
  return{
  ...state,
  isLoading: false,
  message: action?.error?.message || "Error updating QR code",
  isError: true,
}};

const deleteQrRequest = (state:any) => ({
  ...state,
  isLoading: true,
  isError: false,
});

const deleteQrSuccess = (state:any, action:any) => {
  
  return { ...state, isLoading: false, isError: false };
};

const deleteQrFailure = (state:any, action:any) => ({
  ...state,
  isLoading: false,
  message: action?.error?.message || "Error deleting QR code",
  isError: true,
});
export const setQrState = (state: any, action: any) => {
  const newState = {
    ...state,
  };
  newState[action.key] = action.value;
  return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_QR_REQUEST]: getQrRequest,
  [Types.GET_QR_SUCCESS]: getQrSuccess,
  [Types.GET_QR_FAILURE]: getQrFailure,

  [Types.GENERATE_QR_REQUEST]: generateQrRequest,
  [Types.GENERATE_QR_SUCCESS]: generateQrSuccess,
  [Types.GENERATE_QR_FAILURE]: generateQrFailure,

  [Types.UPDATE_QR_REQUEST]: updateQrRequest,
  [Types.UPDATE_QR_SUCCESS]: updateQrSuccess,
  [Types.UPDATE_QR_FAILURE]: updateQrFailure,

  [Types.DELETE_QR_REQUEST]: deleteQrRequest,
  [Types.DELETE_QR_SUCCESS]: deleteQrSuccess,
  [Types.DELETE_QR_FAILURE]: deleteQrFailure,
  [Types.SET_QR_STATE]: setQrState,
  
});
